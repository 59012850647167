@import "constants";

.main-content {
    background: white;
    border-radius: $border-radius;
    width: 100%;

    img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        width: 100%;
    }

    .video-container {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        overflow: hidden;
    }

    .body {
        padding: 2rem;

        .body-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }
    }
}
