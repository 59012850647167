@import "constants";

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 2px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Nunito Sans", sans-serif;
    font-size: 18px;
}

header {
    margin-bottom: 2rem;
}

body {
    background-image: linear-gradient(
            to top right,
            adjust-color($blue, $alpha: -0.25),
            adjust_color($pink, $alpha: -0.25)
        ),
        url("../img/background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

h1 {
    font-size: $text-xx-large;
    font-weight: $text-bold;
}

h2 {
    font-size: $text-large;
    font-weight: $text-light;
}

h3 {
    font-size: $text-large;
    $font-weight: $text-bold;
}

input,
textarea {
    border: none;
    border-bottom: 1px solid $pink;
    margin-bottom: 1rem;
    padding: 0.25rem 0;
    resize: none;
    width: 100%;
}

label {
    display: block;
    font-weight: $text-bold;
}

p {
    margin-bottom: 1rem;
}

p:last-of-type {
    margin-bottom: 0;
}

video {
    width: 100%;
}
